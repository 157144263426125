var _ = require('underscore');

var absoluteTester = new RegExp('^(?:[a-z]+:)?//', 'i');

var isAbsoluteUrl = function(url) {
  return absoluteTester.test(url);
};

module.exports = {
  toQueryString: function() {
    return window.location.href.split( '?' )[ 1 ] || '';
  },

  toQueryParams: function() {
    var queryString = this.toQueryString();

    if ( !queryString ) {
      return [];
    }

    var params = queryString.split( '&' );
    return _.map( params, function( param ) {
      var pair = param.split( '=' );
      return {
        type: pair[ 0 ],
        value: pair[ 1 ]
      }
    } );
  },

  searchValue: function() {
    var params = this.toQueryParams();
    var searchParam = _.findWhere( params, {
      type: 'search'
    } );

    if ( !searchParam ) {
      return '';
    }

    var decodedString = decodeURIComponent( searchParam.value );
    return decodedString.split('+').join(' ');
  },

  getParamValue: function( name ) {
    return decodeURIComponent( ( new RegExp( '[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)' ).exec( location.search ) || [ , "" ] )[ 1 ].replace( /\+/g, '%20' ) ) || null;
  },

  addParam: function(url, param) {
    var key = _.keys(param)[0];
    var queryParam = key + '=' + param[key];
    var symbol = url.indexOf('?') >= 0 ? '&' : '?';
    return url + symbol + queryParam;
  },

  isAbsoluteUrl: isAbsoluteUrl,

  isRelativeUrl: function(url) {
    return !isAbsoluteUrl(url);
  }
};
