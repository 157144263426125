var Bookmarks = require( '../bookmarks/bookmarks-controller.js' );
var ProfileModel = require( '../../components/profile/full-profile-model' );

module.exports = Marionette.LayoutView.extend( {
  template: '#menu-template',

  className: 'menu',

  regions: {
    bookmarksRegion: '.bookmarks-container'
  },

  events: {
    'click .hamburger': 'toggleMenu',
    'click .qualifications-icon': 'toggleMenu',
    'click .work-icon': 'toggleMenu'
  },
  ui: {
    dashboard: '.dashboard',
    earningsHistory: '.earnings-history',
    qualifications: '.qualifications-section'
  },

  initialize: function() {
    this.bookmarks = new Bookmarks( {
      region: this.bookmarksRegion
    } );
    this.profileModel = new ProfileModel();
    this.fetchingProfile = this.profileModel.fetch();
  },

  hideNavItems: function(profileModel) {
    const isPrivateContributor = profileModel.get('workerLaborChannel').offPlatformPayments;
    if(isPrivateContributor) {
      this.ui.dashboard.addClass('hidden');
      this.ui.earningsHistory.addClass('hidden');
      this.ui.qualifications.addClass('hidden');
    }
  },

  toggleMenu: function( e ) {
    App.vent.trigger( 'toggle:menu' );
  },

  onBeforeShow: function() {
    $.when( this.fetchingProfile )
      .then( this.hideNavItems.bind( this ) );
    this.bookmarks.show();
  }
} );
