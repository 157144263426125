var SearchView = require( './search-view' );
var SearchModel = require( './search-model' );
var urlParser = require( '../helpers/url-parser' );

module.exports = Marionette.Object.extend( {
  initialize: function( options ) {
    this.searchModel = new SearchModel( {
      query: urlParser.searchValue()
    } );

    this.searchView = new SearchView( {
      model: this.searchModel
    } );

    this.listenTo( this.searchView, 'search', this.navigate );
    this.listenTo( App.vent, 'remove:searchFilter', this.clearSearch );

    var region = options.showIn;
    region.show( this.searchView );
  },

  clearSearch: function() {
    this.searchModel.updateQuery( null );
  },

  navigate: function( model ) {
    window.location = model.url();
  }
} )
