var URI = require( 'urijs' );

var BookmarkLinkView = Marionette.ItemView.extend( {
  template: '#bookmark-menu-item-template',
  tagName: 'li'
} );

var EditBookmarkView = Marionette.ItemView.extend( {
  template: '#bookmark-manage-item-template',
  tagName: 'li',
  events: {
    'click .remove-bookmark': 'removeBookmark'
  },
  removeBookmark: function( e ) {
    e.preventDefault();
    this.model.destroy();
    var deleteLi = this.$( 'span.small' ).text();

    $( '#bookmarks-list li' ).filter( function( index ) {
      if ( $( "a", this ).text() == deleteLi ) {
        $( this ).remove();
      }
    } );

  }
} );

var BookmarksView = Marionette.CompositeView.extend( {
  initialize: function( options ) {
    this.editable = options.editable;
  },
  getTemplate: function() {
    return this.editable ? '#bookmarks-management-template' : '#bookmarks-list-layout-template';
  },
  getChildView: function() {
    return this.editable ? EditBookmarkView : BookmarkLinkView;
  },
  childViewContainer: 'ul',
  events: {
    'click .save-bookmark': 'saveBookmark',
    'keypress input': 'onKeypressField'
  },
  saveBookmark: function( e ) {
    e.preventDefault();

    this.collection.create( {
      title: this.$( 'input' ).val(),
      typeCode: 'jobs'
    }, {
      validate: true
    } );

    var savedSearches = $( 'a[href^="#bookmarks-list"]' ),
      bookmarksList = $( '#bookmarks-list' );

    if ( this.$( 'input' ).val() ) {
      bookmarksList.prepend( '<li><a href="/find-work' + window.location.search + '">' + _.escape(this.$( 'input' ).val()) + '</a></li>' );
      savedSearches.parents().eq( 2 ).addClass( 'added' );
      setTimeout( function() {
        savedSearches.parents().eq( 2 ).removeClass( 'added' )
      }, 500 );
    }

    this.$( 'input' ).val( '' );
  },
  onKeypressField: function() {
    this.toggleError()
  },
  toggleError: function( message, options ) {
    var options = options || {};
    var $target = this.$( 'input.form-control' ).parent();
    var hasError = message ? true : false;
    var hidden = options && options.show ? false : true;

    $target.toggleClass( 'has-error', hasError );
    $target.find( '.error-message' ).toggleClass( 'hidden', hidden );
  }
} );

var BookmarkModel = Backbone.Model.extend( {
  idAttribute: 'bookmarkId',
  defaults: {
    title: null,
    queryString: null
  },
  validate: function( attrs ) {
    if ( !attrs.title ) {
      return 'Please name your search'
    }
  }
} );

var BookmarkCollection = Backbone.Collection.extend( {
  url: '/api/bookmarks',
  model: BookmarkModel,
  parse: function( response ) {
    return response.result.bookmarks;
  },
  // comparator: 'bookmarkId'
}, {
  cache: true
} );

var BookmarksController = Marionette.Object.extend( {
  initialize: function( options ) {
    this.queryString = options.queryString;
    this.region = options && options.region;
    this.bookmarks = new BookmarkCollection();

    this.listenTo( this.bookmarks, 'add', this.setBookmarkQueryString );
    this.listenTo( this.bookmarks, 'invalid', this.toggleError );
    this.listenTo( this.bookmarks, 'error', this.onError );
    this.listenTo( this.bookmarks, 'sync', this.onSync );

    this.bookmarksList = new BookmarksView( {
      editable: options.editable,
      collection: this.bookmarks
    } );

    this.bookmarks.fetch();
  },

  setBookmarkQueryString: function( bookmark ) {
    var uriQuery = URI( window.location ).query();
    if ( bookmark.isNew() ) {
      bookmark.set( 'queryString', uriQuery );
    }
  },

  onSync: function() {
    this.toggleError();
  },

  onError: function( model, collection, response ) {
    this.bookmarks.remove( model );
    this.toggleError( model, 'Save Failed', {
      show: true
    } );
  },

  toggleError: function( model, error, options ) {
    this.bookmarksList.toggleError( error, options );
  },

  show: function() {
    this.region.show( this.bookmarksList );
  },

  html: function() {
    return this.bookmarksList.render().el;
  }
} );

module.exports = BookmarksController;
