//page components
var Search = require( '../../components/search/search-controller' );
var AvatarMenu = require( './avatar-menu/avatar-menu-controller' );

/**
 * Marionette Header Layout module.
 * @module components/header
 */

/** Define the template and classes for the header component. */
module.exports = Marionette.LayoutView.extend( {
  template: '#header-template',
  className: 'row',
  regions: {
    topSearchRegion: '.top-search',
    widgetsRegion: '.widgets'
  },
  events: {
    'click #close-hdr': 'unmorphIt'
  },
  initialize: function() {
    this.avatarMenu = new AvatarMenu( {
      region: this.widgetsRegion
    } );
  },
  onBeforeShow: function() {
    new Search( {
      showIn: this.topSearchRegion
    } );

    this.avatarMenu.show();
  },
  unmorphIt: function() {
    $( '#main-header' ).removeClass( 'morphit' );
  }
} );
