var ModalModel = require( './modal-model' );
var ModalView = require( './modal-view' );

var Modal = Marionette.Object.extend( {
  initialize: function( options ) {
    this.model = new ModalModel( options );
    this.modalView = new ModalView( {
      model: this.model
    } );

    App.modalRegion.show( this.modalView );

    this.listenTo( App.vent, 'modal:show', this.updateContent );
    this.listenTo( App.vent, 'modal:hide', this.hide );
    this.listenTo( this.modalView, 'modal:close', this.onClose );
    this.listenTo( this.modalView, 'modal:shown', this.ventShown );
  },

  show: function() {
    this.modalView.show();
  },

  hide: function() {
    this.modalView.hide();
  },

  ventShown: function() {
    App.vent.trigger( 'modal:shown' );
  },

  updateContent: function( data ) {
    // title, view
    this.onCloseCallback = data.onModalClose;
    this.modalView.updateContent( data );
  },

  onClose: function() {
    if ( this.onCloseCallback ) this.onCloseCallback();
  }
} );

module.exports = Modal;

// App.commands.setHandler( 'init:modal', function( options ) {
// 	new Modal.Controller( options );
// } );
