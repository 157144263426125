var AvatarMenuModel = require( '../../profile/basic-profile-model' );

var AvatarMenuView = Marionette.ItemView.extend( {
  template: '#avatar-menu-template',
  tagName: 'li',
  className: 'widget',
  modelEvents: {
    'change': 'render'
  }
} );

var AvatarMenuController = Marionette.Object.extend( {
  initialize: function( options ) {
    this.region = options.region;
    this.model = new AvatarMenuModel();
    this.view = new AvatarMenuView( {
      model: this.model
    } );
    this.model.fetch();

    this.listenTo( App.vent, 'change:profile-personal', this.onChangeProfile );
  },

  onChangeProfile: function( data ) {
    Backbone.fetchCache.clearItem( 'basicProfileInfo' );
    this.model.set( data );
  },

  show: function() {
    this.region.show( this.view );
  }
} );

module.exports = AvatarMenuController;
