var TypeSelectModel = Backbone.Model.extend( {
  defaults: {
    types: [ {
      icon: 'fa-briefcase',
      handle: 'find-work',
      label: 'Work'
    } ],
    searchType: 'fa-search',
    handle: 'all'
  }
} );

var TypeSelectView = Marionette.LayoutView.extend( {
  template: '#search-type-select',
  events: {
    'click @ui.searchType a': 'setSearchType'
  },
  ui: {
    searchType: '.os-search-type'
  },
  modelEvents: {
    'change searchType': 'render'
  },
  setSearchType: function( e ) {
    var val = $( e.target ).data( 'type' );

    var types = this.model.get( 'types' );
    var selectedType = _.findWhere( types, {
      handle: val
    } );

    this.model.set( {
      searchType: selectedType.icon,
      handle: selectedType.handle
    } );
  },
} );

module.exports = Marionette.LayoutView.extend( {
  template: '#top-search-template',
  className: 'top-search-wrap',

  regions: {
    selectRegion: '.search-type-select'
  },

  ui: {
    searchInput: 'input'
  },

  events: {
    'click #os-search': 'search',
    'keyup #top-searcher': 'onKeyUp',
    'focus #top-searcher': 'morphIt',
    'change @ui.searchInput': 'setQuery'
  },

  modelEvents: {
    'change:query': 'updateInput'
  },

  initialize: function() {
    this.typeSelect = new TypeSelectView( {
      model: new TypeSelectModel()
    } );

    this.listenTo( this.typeSelect.model, 'change', this.onTypeChange );
  },

  onBeforeShow: function() {
    this.selectRegion.show( this.typeSelect );
  },

  setQuery: function( e ) {
    var text = $( e.target ).val();
    this.model.set( 'query', text );
  },

  updateInput: function() {
    this.ui.searchInput.val( this.model.get( 'query' ) );
  },

  onTypeChange: function( typeSelectModel ) {
    this.model.set( 'searchType', typeSelectModel.get( 'handle' ) );
  },

  onKeyUp: function( e ) {
    if ( e.which == 13 ) {
      this.search();
    }
  },

  search: function() {
    this.trigger( 'search', this.model );
  },

  morphIt: function() {
    // $( '#main-header' ).addClass( 'morphit' );
  }
} );
