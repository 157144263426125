var App = new Marionette.Application();
var Header = require( './components/header/header-view' );
var Menu = require( './components/menu/menu-view' );
var Footer = require( './components/footer/footer-view' );
var Modal = require( './components/modal/modal-controller' );


App.addRegions( {
  headerRegion: '#main-header',
  menuRegion: '#main-menu',
  mainRegion: '#main-content .main-content-region',
  footerRegion: '#main-footer',
  modalRegion: '#modal-region',
} );

$.ajaxSetup( {
  headers: {
    'X-CSRF-TOKEN': $( 'meta[name="crumb"]' ).attr( 'content' )
  }
} );

$( function() {
  App.start();

  //ability to navigate through tabs by adding #tabname
  if ( location.hash !== '' ) $( 'a[href="' + location.hash + '"]' ).tab( 'show' );
  return $( 'a[data-toggle="tab"]' ).on( 'shown', function( e ) {
    return location.hash = $( e.target ).attr( 'href' ).substr( 1 );
  } );

} );

App.on( 'start', function() {
  Backbone.history.start();

  if ( window.loggedOut ) {
    App.footerRegion.show( new Footer() );
  } else {
    new Modal();
    App.headerRegion.show( new Header() );
    App.menuRegion.show( new Menu() );
    App.footerRegion.show( new Footer() );
  }
} );

module.exports = App;
