module.exports = Backbone.Model.extend( {
  defaults: {
    searchType: 'find-work',
    query: null
  },

  url: function() {
    return '/' + this.get( 'searchType' ) + this.toQueryString( this.get( 'query' ) );
  },

  toQueryString: function( searchString ) {
    return searchString ? ( '?search=' + searchString.split( ' ' ).join( '+' ) ) : '';
  },

  updateQuery: function( query ) {
    this.set( 'query', query );
  }
} );
