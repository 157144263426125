/* Polyfills for IE */
//require('es6-symbol/implement');
//require('es6-shim');
import "babel-polyfill";

var uri = require( 'urijs' );
var $ = require( 'jquery' );
var _ = require( 'underscore' );

if ( uri( window.location.search ).hasQuery( 'fresh' ) ) {
  window.localStorage.removeItem( 'backboneCache' );
}
window.$ = $;
window.jQuery = $;
window._ = _;
window.this = {};
require( 'backbone' ).$ = $ || jQuery;
window.Backbone = require( 'backbone' );
window.Marionette = require( 'backbone.marionette' );
window.Backbone.Validation = require( 'backbone-validation' );
window.BackboneCache = require( './packages/backbone-fetch-cache' );
window.moment = require( 'moment' );
require( './packages/jquery.simplePagination' );
window.App = require( './app' );

/** Redirect to login when ajax request completes and session is expired. */
$( document ).ajaxComplete( function( event, response ) {
  if ( response.responseText.indexOf( '!DOCTYPE html' ) >= 0 && response.status === 200 ) {
    window.location.reload();
  }
} );

var menu_state = localStorage.getItem( 'menu_state' );
var bookmark_state = localStorage.getItem( 'bookmark_state' );
App.vent.on( 'toggle:menu', function() {
  $( '#content-wrap' ).toggleClass( 'menu-open' );
  $( 'body' ).toggleClass( 'open-nav' );
  if ( menu_state ) {
    if ( menu_state == 'open' ) {
      localStorage.setItem( 'menu_state', 'closed' );
    } else {
      localStorage.setItem( 'menu_state', 'open' );
    }
  } else {
    localStorage.setItem( 'menu_state', 'open' );
  }
} );
if ( menu_state == 'open' ) {
  $( '#content-wrap' ).addClass( 'menu-open' );
}
App.vent.on( 'loading', function() {
  $( 'body' ).toggleClass( 'loading' );
} );
var stickify = function() {
  var $el = $( '.fixedElement' );
  var isPositionFixed = ( $el.css( 'position' ) == 'fixed' );
  if ( $( this ).scrollTop() > 89 && !isPositionFixed ) {
    $el.removeClass( 'absoluteTopZero' ).addClass( 'fixedTopZero' );
  }
  if ( $( this ).scrollTop() < 89 && isPositionFixed ) {
    $el.removeClass( 'fixedTopZero' ).addClass( 'absoluteTopZero' )
  }
};
  /** bind hide bookmark to document */
$( document ).on( 'hide.bs.collapse', '.collapse', function() {
  localStorage.setItem( 'bookmark_state', 'close' );
} );
/** bind show bookmark to document */
$( document ).on( 'show.bs.collapse', '.collapse', function() {
  localStorage.setItem( 'bookmark_state', 'open' );
} );
/** Self-executing function */
$( function() {
  $( '#main-menu' ).css( {
    position: 'absolute'
  } );
  /** If the bookmark state is set, else set it */
  if ( bookmark_state ) {
    if ( bookmark_state == 'open' ) {
      $( '.collapse' ).collapse( 'show' );
    }
    if ( bookmark_state == 'closed' ) {
      $( '.collapse' ).collapse( 'hide' );
    }
  } else {
    localStorage.setItem( 'bookmark_state', 'closed' );
  }
  $( 'a.disabled' ).on( 'click', function( e ) {
    e.preventDefault();
    return false;
  } );
  $( '[data-toggle="tooltip"]' ).tooltip( {
    trigger: 'hover'
  } );
  $( '[data-toggle="popover"]' ).popover( {
    trigger: 'hover'
  } );
  $( 'html' ).on( 'click', function( e ) {
    if ( typeof $( e.target ).data( 'original-title' ) == 'undefined' && !$( e.target ).parents().is( '.popover.in' ) ) {
      $( '[data-original-title]' ).popover( 'hide' );
    }
  } );
  $( '#main-menu .menu .list-unstyled li' ).on( 'click', function() {
    $( this ).addClass( 'active' );
  } )
} );

$( document ).ready( function() {
  if ( ( window.console || 'console' in window ) && window.location.host !== 'localhost:8080' ) {
    console.log( '%cOneSpace', 'font-weight:300;color:#505553;font-size:69px;font-family \'Open Sans\', sans-serif;' );
    console.log( '%cRedefine your workspace with the most comprehensive platform for managing flexible, cloud-based talent.', 'color:#505553; font-size:14px;font-family \'Open Sans\', sans-serif;' );
    console.log( '%cImagine what we can accomplish together. http://www.onespace.com/about/careers/', 'color:#505553; font-size:14px;font-family \'Open Sans\', sans-serif;' );
  }
} );

$( window ).scroll( stickify );
