var ModalView = Marionette.ItemView.extend( {
  template: '#modal-template',

  className: 'modal fade',

  ui: {
    header: '.modal-header',
    action: '.action-link',
    title: '.modal-title',
    body: '.modal-body'
  },

  events: {
    'click @ui.action': 'onClickAction',
    'shown.bs.modal': 'ventShown',
    'hide.bs.modal': 'onCloseModal'
  },

  ventShown: function() {
    this.trigger( 'modal:shown' );
  },

  onCloseModal: function() {
    this.trigger( 'modal:close' );
  },

  initialize: function() {
    var view = this;

    this.$el.modal( {
      show: false
    } )

    this.listenTo( this.model, 'change:title', this.renderTitle );
    this.listenTo( this.model, 'change:bodyView', this.renderBodyView );
    this.listenTo( this.model, 'change:size', this.renderModalContainer );
  },

  renderTitle: function( modal ) {
    var title = this.model.get( 'title' );
    if ( title === '' || title === null ) {
      this.ui.header.hide();
    } else {
      this.ui.header.show();
      this.ui.title.text( title );
    }
  },

  renderBodyView: function( modal ) {
    var body = this.model.get( 'bodyView' );

    if ( typeof body === 'string' ) {
      this.ui.body.html( body );
    } else {
      this.ui.body.html( body.render().el );
    }
  },

  renderModalContainer: function() {
    var size = this.model.get( 'size' ) || '';
    this.$( '.modal-dialog' ).removeClass().addClass( 'modal-dialog ' + size );
  },

  updateContent: function( data ) {
    var existingView = this.model.get( 'bodyView' );
    var newView = data.view || null;

    if ( existingView !== null && typeof existingView !== 'string' ) {
      existingView.remove();
    }

    this.model.set( 'title', data.title );
    this.model.set( 'bodyView', newView );
    this.model.set( 'action', data.action );
    this.model.set( 'size', data.size );
    this.show();
  },

  show: function() {
    this.$el.modal( 'show' );
  },

  hide: function() {
    this.$el.modal( 'hide' );
  },

  onClickAction: function() {
    var action = this.model.get( 'action' );
    action();
  }
} );

module.exports = ModalView;
