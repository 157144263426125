module.exports = Backbone.Model.extend( {
  url: '/api/profile',

  getCacheKey: function() {
    return 'basicProfileInfo'
  },

  defaults: {
    firstName: '',
    email: '',
    photoUrl: ''
  },

  parse: function( response ) {
    window.user = response.workerId;
    return {
      firstName: response.firstName || response.name.first,
      email: response.emailAddress,
      photoUrl: ( response.personal && response.personal.photoUrl ) || '/static/img/placeholder.png'
    }
  }
}, {
  cache: true
} );
